<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useDefaultItemMenu } from './defaultItemMenu';
import { useCopy } from '@/compositions/copy';
import { formatCoordinates } from '@/utils';

export default {
  name: 'GeotagCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();

    const { genMenu: genDefaultItemMenu } = useDefaultItemMenu();

    const items = computed(() => [
      genSubheaderItem('Address'),
      genDefaultItem({
        title: 'Country',
        subTitle: entity.value?.addressCountry?.value,
        icon: '$country',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressCountry)
      }),
      genDefaultItem({
        title: 'City',
        subTitle: entity.value?.addressCity?.value,
        icon: '$city',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressCity)
      }),
      genDefaultItem({
        title: 'Street',
        subTitle: entity.value?.addressStreet?.value,
        icon: '$location_city',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressStreet)
      }),
      genDefaultItem({
        title: 'Building',
        subTitle: entity.value?.addressBuilding?.value,
        icon: '$building',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressBuilding)
      }),
      genDefaultItem({
        title: 'Room',
        subTitle: entity.value?.addressRoom?.value,
        icon: '$door',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressRoom)
      }),
      genDefaultItem({
        title: 'Address postcode',
        subTitle: entity.value?.addressPostcode?.value,
        icon: '$postcode',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.addressPostcode)
      }),
      genSubheaderItem('Position'),
      genDefaultItem({
        title: 'Center Lat,Lon',
        subTitle: formatCoordinates(
          entity.value?.positionBaseCoordinate?.value,
          true
        ),
        icon: '$marker_set',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.positionBaseCoordinate)
      }),
      genDefaultItem({
        title: 'Base altitude',
        subTitle: entity.value?.positionBaseAltitude?.value,
        icon: '$base_altitude',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.positionBaseAltitude)
      }),
      genDefaultItem({
        title: 'Min altitude',
        subTitle: entity.value?.positionMinAltitude?.value,
        icon: '$min_altitude',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.positionMinAltitude)
      }),
      genDefaultItem({
        title: 'Max altitude',
        subTitle: entity.value?.positionMaxAltitude?.value,
        icon: '$max_altitude',
        invert: true,
        actions: genDefaultItemMenu(entity.value?.positionMaxAltitude)
      }),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      }),
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ])
    ]);

    return { items };
  }
};
</script>

<style></style>
